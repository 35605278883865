<template>
  <Catalog @new="newEntity" v-model="entity" :validate="validate" name="Almacen" :rules="rules" :headers="headers" :inputs="inputs" />
</template>
<script>
import { InventoryWarehouse } from '../../../models/inventarios/InventoryWarehouse';
import { HeaderGrid, Rule, CatalogInput, ValidateForm } from '../../../utilities/General';
import Catalog from "../../../components/templates/BasicCatalog.vue";
import Session from '../../../mixins/sessionMixin';
export default {
    data() {
        return {
    entity: new InventoryWarehouse(),
      inputs: [
        new CatalogInput("Nombre", "name", "text", {cols: 6}),
        new CatalogInput("Clave", "key_name", "text", {cols: 4}),
        new CatalogInput("Activo","is_active","switch")
      ],
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" })
      ],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Clave", "key_name")
      ],
      validate: new ValidateForm()
        };
    },
    mixins: [Session],
    components: { Catalog },
      methods: {
    newEntity() {
      this.entity = new InventoryWarehouse(this.session); 
      this.validate.clear();
    },
  },
  async created() {
    this.validate = new ValidateForm(this.rules);
    this.entity = new InventoryWarehouse(this.session); 
  }
}
</script> 
 
<style scoped lang="scss">
</style > 
  